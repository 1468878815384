* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: #fff;
  height: 100%;
}

a {
  text-decoration: none;
}

#root {
  height: 100%;
  margin: 0;
}

/* .hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.hide-scrollbar {
  overflow: -moz-scrollbars-none;
} */
